@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 44 42% 95%;
    --foreground: 44 5% 10%;
    --card: 44 42% 90%;
    --card-foreground: 44 5% 15%;
    --popover: 44 42% 95%;
    --popover-foreground: 44 95% 10%;
    --primary: 44 96% 86%;
    --primary-foreground: 0 0% 0%;
    --secondary: 44 30% 70%;
    --secondary-foreground: 0 0% 0%;
    --muted: 6 30% 85%;
    --muted-foreground: 44 5% 40%;
    --accent: 6 30% 80%;
    --accent-foreground: 44 5% 15%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 44 30% 50%;
    --input: 44 30% 42%;
    --ring: 44 96% 86%;
    --radius: 1rem;
  }

  .dark {
    --background: 44 27% 10%;
    --foreground: 44 5% 90%;
    --card: 44 27% 10%;
    --card-foreground: 44 5% 90%;
    --popover: 44 27% 5%;
    --popover-foreground: 44 5% 90%;
    --primary: 44 96% 86%;
    --primary-foreground: 0 0% 0%;
    --secondary: 44 27% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 6 27% 25%;
    --muted-foreground: 44 5% 60%;
    --accent: 6 27% 25%;
    --accent-foreground: 44 5% 90%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 44 27% 50%;
    --input: 44 27% 50%;
    --ring: 44 96% 86%;
    --radius: 1rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {

    @apply bg-background text-foreground font-inter;
  }
}